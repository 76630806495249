import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import thankyouimg from "../../assets/images/thankyou.png";
import "./thank-you.scss";
import { Helmet } from "react-helmet";

function Thankyou() {

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <Container fluid className="px-0">
       <Helmet>
        {/* <title>
        Contact Synoverge | Get in Touch Now  | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="
          We are here to support the success of your company. Reach out to Synoverge. Get in touch for professional IT assistance, questions, and partnerships."
        />
        <link rel="canonical"href="https://www.synoverge.com/contact-us" /> */}
      </Helmet>
      <div className="error-page">
        <div className="module text-center">
          <Container>
            <Row>
            <Col md={12}>
              <img src={thankyouimg} alt="Thank-you" title="Thank-you" />
              <h3>Thank You For Contacting Us</h3>
              <p className="mb-0">Our specialists will take a close look at your requirements and you will reach out soon with the relevant experts. <br></br>We usually reply within a 1 or 2 business days.</p> <br></br>
             <p>If needed, we’re happy to sign a Non-Disclosure Agreement (NDA) before the call.</p>
              <Button className="btn btn-knowmore" as={Link as any} to="/">
                Go to Home
              </Button>
            </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Container>
  );
}

export default Thankyou;
