import { Carousel, Col, Container, Row } from "react-bootstrap";

function WhatOurClientsSay() {
  const testimonial = [
    {
      content1:
        "Synoverge team has done an outstanding work and shown great willingness to adapt to our needs/requirements. I appreciate the team’s flexibility to meet our changing needs/goals. We are looking forward to continuing to do business together.",

      author1: "President & CEO",

      expertise1: "(a leading agriculture yield management platform in the US)",

      // userico1: { usericon1 },

      content2:
        "Using Synoverge has allowed us to deliver higher quality products and services in a timely fashion. Synoverge’s project management and team allocation approach has increased our project success rates and shortened our execution cycles.",

      author2: "CTO",

      expertise2:
        "(a niche ISV providing law &amp; enforcement solutions to state agencies in US)",

      //userico2: { usericon2 },

      content3:
        "It’s been a pleasure working with Synoverge team and Project Manager in particular…. amazing attitude and determination. Keep up the good work.",

      author3: "VP, Technology Services",

      expertise3: "(one of the global leaders providing professional services)",

      //userico3: { usericon1 },

      content4:
        "Synoverge team has done an outstanding work and shown great willingness to adapt to our needs/requirements. I appreciate the team’s flexibility to meet our changing needs/goals. We are looking forward to continuing to do business together.",

      author4: "President & CEO",

      expertise4: "(a leading agriculture yield management platform in the US)",

      //userico4: { usericon2 }
    },
  ];

  return (
    <>
      <section className="client-say">
        <Container>
          <h2 className="sectitle">
            What our <span>Clients Say</span>
          </h2>
          <div>
            <Carousel interval={5000} className="carousel desktop-view">
              {testimonial.map((c, index) => {
                const slides = [];
                if (c.content1 && c.content2) {
                  slides.push(
                    <Carousel.Item
                      key={`testimonial-${index}-1`}
                      interval={5000}
                    >
                      <div className="testimonial">
                        <Row>
                          <Col lg={6}>
                            <div className="testimonial-bg">
                              <div className="testimonial-content">
                                <p>"{c.content1}"</p>
                              </div>
                              <div className="testimonial-author">
                                <h5>{c.author1}</h5>
                                <p className="expertise">{c.expertise1}</p>
                              </div>
                            </div>
                          </Col>
                          {/* Right Side Column */}
                          <Col lg={6}>
                            <div className="testimonial-bg">
                              <div className="testimonial-content">
                                <p>"{c.content2}"</p>
                              </div>
                              <div className="testimonial-author">
                                <h5>{c.author2}</h5>
                                <p className="expertise">{c.expertise2}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Carousel.Item>
                  );
                }
                if (c.content3 && c.content4) {
                  slides.push(
                    <Carousel.Item
                      key={`testimonial-${index}-2`}
                      interval={5000}
                    >
                      <div className="testimonial">
                        <Row>
                          <Col lg={6}>
                            <div className="testimonial-bg">
                              <div className="testimonial-content">
                                <p>"{c.content3}"</p>
                              </div>
                              <div className="testimonial-author">
                                <h5>{c.author3}</h5>
                                <p className="expertise">{c.expertise3}</p>
                              </div>
                            </div>
                          </Col>
                          {/* Right Side Column */}
                          <Col lg={6}>
                            <div className="testimonial-bg">
                              <div className="testimonial-content">
                                <p>"{c.content4}"</p>
                              </div>
                              <div className="testimonial-author">
                                <h5>{c.author2}</h5>
                                <p className="expertise">{c.expertise4}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Carousel.Item>
                  );
                }
                if (c.content3 && c.content4) {
                  slides.push(
                    <Carousel.Item
                      key={`testimonial-${index}-3`}
                      interval={5000}
                    >
                      <div className="testimonial">
                        <Row>
                          <Col lg={6}>
                            <div className="testimonial-bg">
                              <div className="testimonial-content">
                                <p>"{c.content3}"</p>
                              </div>
                              <div className="testimonial-author">
                                <h5>{c.author3}</h5>
                                <p className="expertise">{c.expertise3}</p>
                              </div>
                            </div>
                          </Col>
                          {/* Right Side Column */}
                          <Col lg={6}>
                            <div className="testimonial-bg">
                              <div className="testimonial-content">
                                <p>"{c.content4}"</p>
                              </div>
                              <div className="testimonial-author">
                                <h5>{c.author2}</h5>
                                <p className="expertise">{c.expertise4}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Carousel.Item>
                  );
                }
                if (c.content3 && c.content4) {
                  slides.push(
                    <Carousel.Item
                      key={`testimonial-${index}-4`}
                      interval={5000}
                    >
                      <div className="testimonial">
                        <Row>
                          <Col lg={6}>
                            <div className="testimonial-bg">
                              <div className="testimonial-content">
                                <p>"{c.content3}"</p>
                              </div>
                              <div className="testimonial-author">
                                <h5>{c.author3}</h5>
                                <p className="expertise">{c.expertise3}</p>
                              </div>
                            </div>
                          </Col>
                          {/* Right Side Column */}
                          <Col lg={6}>
                            <div className="testimonial-bg">
                              <div className="testimonial-content">
                                <p>"{c.content4}"</p>
                              </div>
                              <div className="testimonial-author">
                                <h5>{c.author2}</h5>
                                <p className="expertise">{c.expertise4}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Carousel.Item>
                  );
                }
                return slides;
              })}
            </Carousel>

            <Carousel interval={5000} className="carousel mobile-view">
              {testimonial.map((c, index) => {
                const slides = [];
                if (c.content1 && c.content2) {
                  slides.push(
                    <Carousel.Item
                      key={`testimonial-${index}-5`}
                      interval={5000}
                    >
                      <div className="testimonial">
                        <Row>
                          <Col lg={12}>
                            <div className="testimonial-bg">
                              <div className="testimonial-content">
                                <p>"{c.content1}"</p>
                              </div>
                              <div className="testimonial-author">
                                <h5>{c.author1}</h5>
                                <p className="expertise">{c.expertise1}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Carousel.Item>
                  );
                }
                if (c.content3 && c.content4) {
                  slides.push(
                    <Carousel.Item
                      key={`testimonial-${index}-6`}
                      interval={5000}
                    >
                      <div className="testimonial">
                        <Row>
                          <Col lg={12}>
                            <div className="testimonial-bg">
                              <div className="testimonial-content">
                                <p>"{c.content2}"</p>
                              </div>
                              <div className="testimonial-author">
                                <h5>{c.author2}</h5>
                                <p className="expertise">{c.expertise2}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Carousel.Item>
                  );
                }
                if (c.content3 && c.content4) {
                  slides.push(
                    <Carousel.Item
                      key={`testimonial-${index}-7`}
                      interval={5000}
                    >
                      <div className="testimonial">
                        <Row>
                          <Col lg={12}>
                            <div className="testimonial-bg">
                              <div className="testimonial-content">
                                <p>"{c.content3}"</p>
                              </div>
                              <div className="testimonial-author">
                                <h5>{c.author3}</h5>
                                <p className="expertise">{c.expertise3}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Carousel.Item>
                  );
                }
                if (c.content3 && c.content4) {
                  slides.push(
                    <Carousel.Item
                      key={`testimonial-${index}-8`}
                      interval={5000}
                    >
                      <div className="testimonial">
                        <Row>
                          <Col lg={12}>
                            <div className="testimonial-bg">
                              <div className="testimonial-content">
                                <p>"{c.content4}"</p>
                              </div>
                              <div className="testimonial-author">
                                <h5>{c.author4}</h5>
                                <p className="expertise">{c.expertise4}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Carousel.Item>
                  );
                }
                return slides;
              })}
            </Carousel>
          </div>
        </Container>
      </section>
    </>
  );
}

export default WhatOurClientsSay;
